import React, { useState } from 'react';
import { ApiInternalInfo } from '@ibe/api';
import Image from 'next/image';
import sanitize from '@/Util/sanitize';

interface Props {
  isMobile: boolean;
  internalInfo?: ApiInternalInfo;
  startDate?: string;
  endDate?: string;
}

const InternalFlightInfo: React.FC<Props> = ({ isMobile, internalInfo, startDate, endDate }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isPopupVisible, setPopupVisible] = useState(false);
  const togglePopup = () => {
    setPopupVisible(!isPopupVisible);
  };

  const isInDateInRange = (
    internalInfoDateFrom: string,
    internalInfoDateTo: string,
    startDate: string,
    endDate: string
  ) => {
    const dateStart = new Date(startDate);
    const dateEnd = new Date(endDate);
    const fromDate = new Date(internalInfoDateFrom);
    const toDate = new Date(internalInfoDateTo);
    return dateStart >= fromDate && dateEnd <= toDate;
  };

  let isDateAvailable = false;

  if (internalInfo?.dateFrom && internalInfo?.dateTo && startDate && endDate) {
    isDateAvailable = isInDateInRange(
      internalInfo.dateFrom,
      internalInfo.dateTo,
      startDate,
      endDate
    );
  }

  return isDateAvailable ? (
    <div>
      <button
        className="internalFlightInfo"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={togglePopup}
      >
        <Image
          src="/placeholder/icons/airplane.svg"
          alt="Flight Icon"
          className="internalFlightInfo__icon"
          width={20}
          height={20}
          loader={p => p.src}
        />
        <div className="internalFlightInfo__text">{internalInfo?.text}</div>
      </button>
      {isHovered && !isMobile && !isPopupVisible && (
        <div className="internalFlightInfo__description">
          <div className="internalFlightInfo__description__content">
            <div
              className="internalFlightInfo__description__content__header"
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  internalInfo?.shortDescriptionTitle ? internalInfo.shortDescriptionTitle : '',
                  true
                )
              }}
            />
            <div
              className="internalFlightInfo__description__content__text"
              dangerouslySetInnerHTML={{
                __html: sanitize(
                  internalInfo?.shortDescriptionContent ? internalInfo.shortDescriptionContent : '',
                  true
                )
              }}
            />
          </div>
        </div>
      )}
      {isPopupVisible && (
        <>
          <div className="internalFlightInfo__overlay" onClick={togglePopup} />
          <div className="internalFlightInfo__popup">
            <div className="internalFlightInfo__popup__content">
              <div className="internalFlightInfo__popup__content__header">
                <div
                  className="internalFlightInfo__popup__content__header__headerText"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(
                      internalInfo?.extendedDescriptionTitle
                        ? internalInfo.extendedDescriptionTitle
                        : '',
                      true
                    )
                  }}
                />
                <button
                  className="internalFlightInfo__popup__content__header__headerClose"
                  onClick={togglePopup}
                >
                  <Image
                    src="/placeholder/icons/close-popup.svg"
                    alt="Close Icon"
                    width={24}
                    height={24}
                    loader={p => p.src}
                  />
                </button>
              </div>
              <div
                className="internalFlightInfo__popup__content__text"
                dangerouslySetInnerHTML={{
                  __html: sanitize(
                    internalInfo?.extendedDescriptionContent
                      ? internalInfo.extendedDescriptionContent
                      : '',
                    true
                  )
                }}
              />
            </div>
          </div>
        </>
      )}
    </div>
  ) : null;
};

export default InternalFlightInfo;
