import React, { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { observer, useLocalStore } from 'mobx-react';
import {
  ApiDurationType,
  ApiGeoUnit,
  ApiItemType,
  ApiListOptions,
  ApiProductData,
  ApiSortDirection,
  ApiSortOption
} from '@ibe/api';
import {
  CollapsibleRef,
  DateInputType,
  DestinationItem,
  LoadingOverlay,
  MEDIAQUERY_DEFAULTS,
  PackageDetailsSelection,
  PackageModule,
  PackageParams,
  PackageSearchStore,
  useApi,
  useClientPersistSearchParams,
  useConfig,
  useMediaQuery,
  useTheme
} from '@ibe/components';
import RenderSearch from '../render/RenderSearch';
import dayjs from 'dayjs';
import useQuery from '@/Util/useQuery';
import PackageParamsTransformer from '@/Util/ParamsTransformer/PackageParamsTransformer';
import { useRouter } from 'next/router';
import { IbeProps } from '@/components/ibe/IbeProps';
import isClient from '@/Util/globals';
import { useSearchProperties } from '@/Util/useSearchProperties';
import useRemountHooks from '@/components/ibe/useRemountHook';
import RenderSearchContent from '@/components/ibe/render/RenderSearchContent';
import RenderListItem from '@/components/ibe/render/RenderListItem';
import dynamic from 'next/dynamic';
import { useMount } from 'react-use';
import {
  CustomPackageSearchStore,
  CustomPackageStore,
  fetchDestinationsGeoUnits,
  fetchDestinationsProducts,
  findDestinationGeoUnits,
  findDestinationProducts,
  MAX_NUMBER_OF_TRAVELERS_ON_ONE_BOOKING,
  UrlEnrichmentData
} from '@/components/ibe/IbeUtils';
import { configService } from '@/Config/config';
import { getDatesFromProps } from '@/components/offer/Offer';
import { useGlobalMGLProps } from '@/Util/GlobalMGLPropsContext';
import InternalFlightModalWarning from '../render/InternalFlightInfo/InternalFlightModalWarning';
import YsnConfigModel from '@/Config/YsnConfigModel';

const CustomIndexedDestinationSelect = dynamic(
  () => import('../render/CustomIndexedDestinationSelect'),
  {
    ssr: false
  }
);

const IbeSearch = observer(function PackageSearch(props: IbeProps) {
  const isMobileView = useMediaQuery({ type: 'max', query: MEDIAQUERY_DEFAULTS.sm });
  const paxRoomSelectionImperativeHandleRef = useRef<{
    toggleDropdownOnly?: (a: boolean) => void;
  }>(null);
  const router = useRouter();
  const query = useQuery();
  const theme = useTheme();
  const config = useConfig() as YsnConfigModel;
  const globalMGLProps = useGlobalMGLProps();
  const api = useApi();
  const { ibeUrl, destinationsUrl, triggerSearch } = props;
  const [isLoading, setLoading] = useState(false);
  const [destinations, setDestinations] = useState<{
    geoUnits: ApiGeoUnit[];
    products: ApiProductData[];
  }>({ geoUnits: [], products: [] });
  const { isMagnoliaEdit } = globalMGLProps?.magnoliaContext || {};

  const [urlEnrichmentData, setUrlEnrichmentData] = useState<UrlEnrichmentData | undefined>(
    undefined
  );
  const [localSearchParams, setLocalSearchParams] = useState<Partial<PackageParams> | undefined>(
    undefined
  );

  const [isOpenMaxOccupancyModal, setIsOpenMaxOccupancyModal] = useState(false);
  const [ysnFlightOnly, setYsnFlightOnly] = useState(false);
  const [isSearchButtonClicked, setIsSearchButtonClicked] = useState(false);

  const customPackageSearchStore = useLocalStore(
    () => new CustomPackageSearchStore(config, api, theme)
  );
  customPackageSearchStore.setShowValidationErrorMessage(true);

  const store = useLocalStore(
    () =>
      new CustomPackageStore(api, config, {
        sortDirection: ApiSortDirection.ASC,
        sortOption: ApiSortOption.PRICE
      })
  );

  useEffect(() => {
    customPackageSearchStore.validateParamsAndShowError();
  }, [isMobileView]);
  const [startDate, endDate] = getDatesFromProps(props, config);

  const [defaultParams, setDefaultParams] = useState<Partial<PackageParams>>({
    occupancy: [
      {
        adults: Number.parseInt(props.numberOfAdults || '1'),
        children: Number.parseInt(props.numberOfChildren || '0'),
        infants: 0,
        childrenAges: [],
        infantsAges: []
      }
    ],
    startDate: startDate || dayjs(new Date()).add(14, 'day').format(config.formatDate),
    endDate: endDate || dayjs(new Date()).add(28, 'day').format(config.formatDate),
    dateInputType: DateInputType.FULL
  });

  const fetchDestination = async (onlyProduct?: boolean) => {
    const codes = [{ code: props.destination } as DestinationItem];
    const req = [
      fetchDestinationsProducts(api, codes || []),
      onlyProduct ? null : fetchDestinationsGeoUnits(api)
    ];
    return Promise.all(req);
  };

  useMount(async () => {
    if (props.destination) {
      const [productsRes, geoUnitsRes] = await fetchDestination();
      setDestinations({
        geoUnits: geoUnitsRes as ApiGeoUnit[],
        products: productsRes as ApiProductData[]
      });
      setUrlEnrichmentData({
        destinations: {
          geoUnits: geoUnitsRes as ApiGeoUnit[],
          products: productsRes as ApiProductData[]
        },
        origins: []
      });
      if (props.destination) {
        const destinationGeoUnits = findDestinationGeoUnits(
          geoUnitsRes as ApiGeoUnit[],
          props.destination
        );
        const destinationProduct = findDestinationProducts(
          productsRes as ApiProductData[],
          props.destination
        );
        if (destinationGeoUnits) {
          setDefaultParams(prevState => ({
            ...prevState,
            destinations: destinationGeoUnits
          }));
        }
        if (destinationProduct) {
          setDefaultParams(prevState => ({
            ...prevState,
            destinations: [destinationProduct]
          }));
        }
      }
    } else {
      const destinationGeoUnits = await fetchDestinationsGeoUnits(api);
      setDestinations({ geoUnits: destinationGeoUnits, products: destinations.products });
      setUrlEnrichmentData({
        destinations: {
          geoUnits: destinationGeoUnits,
          products: destinations.products
        },
        origins: []
      });
    }
  });

  const { setSearchParams } =
    useClientPersistSearchParams<Partial<PackageParams>>(isClient() ? window || null : null) || {};
  const [prefillOnly, setPrefillOnly] = useState(false);
  const { setSearchProperties: setGlobalSearchProperties } = useSearchProperties();

  const destinationCode = useMemo(() => {
    return query.get('destinationCode');
  }, [query]);

  const paramsDecoded = useMemo(() => {
    const paramsDecoded = PackageParamsTransformer.decode(Object.fromEntries(query.entries()));
    query.delete('cmspath');
    paramsDecoded.dateInputType = DateInputType.FULL;
    return paramsDecoded;
  }, [query]);

  useEffect(() => {
    if (destinations.geoUnits.length > 0) {
      if (destinationCode) {
        const foundDes = findDestinationGeoUnits(destinations.geoUnits, destinationCode || '');

        const foundProd = findDestinationProducts(destinations.products, destinationCode || '');
        paramsDecoded.destinations = foundProd ? [foundProd] : foundDes;
      }

      const searchParameters =
        Object.keys(paramsDecoded).length > 1 && !isMagnoliaEdit
          ? {
              ...paramsDecoded
            }
          : { ...defaultParams };
      setLocalSearchParams(searchParameters);
    }
  }, [destinations, defaultParams, JSON.stringify(paramsDecoded), JSON.stringify(destinationCode)]);

  const updateUrlParamsOrShowModal = (params: PackageParams, listOptions: ApiListOptions): void => {
    if (showModalOrNot(customPackageSearchStore, store, ysnFlightOnly)) {
      setIsSearchButtonClicked(true);
      return setIsOpenMaxOccupancyModal(
        showModalOrNot(customPackageSearchStore, store, ysnFlightOnly)
      );
    }
    updateUrlParams(params, listOptions);
  };

  const updateUrlParams = (params: PackageParams, listOptions: ApiListOptions): void => {
    setLoading(true);

    const queryParams = PackageParamsTransformer.encode(params);
    let url = `${ibeUrl}?${queryParams}`;

    if (params.destinations) {
      const destinations_count = destinations.geoUnits.reduce(
        (count, current) => count + 1 + Object.keys(current.children).length,
        0
      );
      const isSearchForEveryDestination =
        params.destinations.filter(value => value.code).length === destinations_count;

      if (isSearchForEveryDestination && destinationsUrl) {
        setLoading(true);
        const queryParams = PackageParamsTransformer.encode(params);
        url = `${destinationsUrl}?${queryParams}`;
      }
    }
    if (ysnFlightOnly) {
      url = url + `&inventories=${JSON.stringify(config.internFlightInventories)}`;
    }

    if (!triggerSearch) {
      setPrefillOnly(false);
    }
    if (!!setSearchParams) {
      setSearchParams(router.asPath.split('?')[0], params);
    }
    if (ibeUrl) {
      router.push(url);
    }
  };

  const updateUrlParamsForSearch = (params: PackageParams, listOptions: ApiListOptions): void => {
    if (params.isFromSearchButton === true) {
      params.goToDetails = true;
    }
    updateUrlParamsOrShowModal(params, listOptions);
  };

  const onPageChangeListener = useCallback(() => {
    const findSearchContainer = document.getElementsByClassName('iso__serviceList__mobile-section');
    if (window && findSearchContainer[0]) {
      const y = (findSearchContainer[0]?.getBoundingClientRect().top || 0) + window.scrollY;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, []);

  const onDestinationChange = (destinations?: DestinationItem[], mainServiceCodes?: string[]) => {
    setLocalSearchParams({ ...localSearchParams, ...{ destinations, mainServiceCodes } });
  };

  const onPackageSearchChange = ({
    changeType
  }: {
    store?: PackageSearchStore;
    changeType: 'duration' | 'Date' | 'participants' | 'origins' | 'destination';
  }) => {
    if (changeType === 'Date') {
      setLocalSearchParams({
        ...localSearchParams,
        ...{
          startDate: customPackageSearchStore.searchParams.startDate,
          endDate: customPackageSearchStore.searchParams.endDate
        }
      });
    }

    if (changeType === 'duration') {
      setLocalSearchParams({
        ...localSearchParams,
        ...{
          duration: customPackageSearchStore.searchParams.duration
        }
      });
    }

    if (changeType === 'origins') {
      setLocalSearchParams({
        ...localSearchParams,
        ...{
          origins: customPackageSearchStore.searchParams.origins
        }
      });
    }
    if (changeType === 'participants') {
      setLocalSearchParams({
        ...localSearchParams,
        ...{
          occupancy: customPackageSearchStore.searchParams.occupancy
        }
      });
      setIsOpenMaxOccupancyModal(showModalOrNot(customPackageSearchStore, store, ysnFlightOnly));
    }
  };

  function renderSearchWithMessage(
    CategorySelect: ReactNode,
    OriginSelect: ReactNode,
    DestinationSelect: ReactNode,
    TravelDatePicker: ReactNode,
    TravelMonthPicker: ReactNode,
    TravelerPicker: ReactNode,
    SearchButton: ReactNode,
    OriginCheckboxes: ReactNode,
    DateRangePickerSingleSelect: ReactNode,
    collapsibleRef?: CollapsibleRef
  ) {
    return RenderSearch(
      CategorySelect,
      OriginSelect,
      DestinationSelect,
      TravelDatePicker,
      TravelMonthPicker,
      TravelerPicker,
      SearchButton,
      OriginCheckboxes,
      DateRangePickerSingleSelect,
      collapsibleRef,
      undefined,
      customPackageSearchStore
    );
  }

  const [keys] = useRemountHooks();

  return (
    <LoadingOverlay isLoading={isLoading}>
      <div className={'ibe-search-component'}>
        <PackageModule
          paxRoomSelectionImperativeHandleRef={paxRoomSelectionImperativeHandleRef}
          customPackageSearchStore={customPackageSearchStore}
          onSearch={updateUrlParamsForSearch}
          onDetail={updateUrlParams}
          searchParams={prefillOnly ? undefined : localSearchParams}
          showLabels
          subType={ApiItemType.PREDEFINEDPACKAGE}
          showMultipleDurations={false}
          showTotalPriceOnly
          endDateMandatory
          withDateRangePicker
          withAdvancedDateRangePicker
          withGroupRequestForm={false}
          withRoomCountSelector={configService.get().enableMultipleRooms}
          renderSearch={renderSearchWithMessage}
          renderSearchContent={RenderSearchContent}
          sortingType={{
            GROUP: [],
            SINGLE: [ApiSortOption.NAME, ApiSortOption.PRICE]
          }}
          sorting={[ApiSortOption.NAME, ApiSortOption.PRICE]}
          minDateRangeSpan={2}
          maxDateRangeSpan={200}
          renderListItem={RenderListItem}
          minSelectableChildAge={0}
          noDatePickerOverflow
          selectLabelSort
          withChildrenSelection
          serviceSearchWithChildAgePicker
          changeOnBlur
          widgetClickable
          packageOriginMustBeSet={false}
          packageOriginWithRemoveAll
          packageOriginWithSelectAll
          customIndexedDestinationSelect={(store: PackageSearchStore) => (
            <CustomIndexedDestinationSelect
              store={store}
              urlEnrichmentData={urlEnrichmentData}
              onDestinationChange={onDestinationChange}
            />
          )}
          dateRangeDurationProps={{
            withDuration: true,
            value: { duration: 7, type: ApiDurationType.DAY },
            usePlaceholderAsOption: false
          }}
          dateRangeUpdateStateFromProps={false}
          dateRangeFillWithLastDateRangeWhenNoSelection
          dateRangeClearSelectionsOnOpen
          doBestPriceSearch
          bestPriceMaxDateRangeSpan={42}
          bestPriceSubmitOnChange
          hideSearchInDetails
          paginationProps={{
            onPageChangeListener
          }}
          key={keys[0]}
          blockInitialSearch
          showPaxSelectionInModal={isMobileView}
          showDateSelectionInModal={isMobileView}
          showSearchParamsPerItem
          paxSelectionRevalidateChildrenOnChangeAfterError
          paxSelectionValidateChildrenPaxSelectionOnChange
          paxSelectionUpdatePaxOnChangeWhenValid
          store={store}
          onPackageSearchChange={onPackageSearchChange}
        />
      </div>
      <InternalFlightModalWarning
        isOpen={isOpenMaxOccupancyModal}
        isSearchButtonClicked={isSearchButtonClicked}
        setIsOpenMaxOccupancyModal={setIsOpenMaxOccupancyModal}
        onNext={() => {
          setYsnFlightOnly(true);
          setIsOpenMaxOccupancyModal(false);
        }}
        onNextSearch={() => {
          setIsSearchButtonClicked(false);
          setIsOpenMaxOccupancyModal(false);
          updateUrlParams(customPackageSearchStore.searchParams, store.listOptions);
        }}
        onClose={() => {
          if (
            paxRoomSelectionImperativeHandleRef.current &&
            paxRoomSelectionImperativeHandleRef.current?.toggleDropdownOnly
          ) {
            paxRoomSelectionImperativeHandleRef.current?.toggleDropdownOnly(true);
          }
        }}
      />
    </LoadingOverlay>
  );
});

export default IbeSearch;

export const showModalOrNot = (
  customPackageSearchStore: PackageSearchStore,
  store: CustomPackageStore,
  ysnFlightOnly: boolean
) => {
  if (customPackageSearchStore?.searchParams?.occupancy) {
    if (areNumberOfTravelersBiggerThanMax(customPackageSearchStore, store) && !ysnFlightOnly) {
      return true;
    }
  }
  return false;
};

export const areNumberOfTravelersBiggerThanMax = (
  customPackageSearchStore: PackageSearchStore,
  store: CustomPackageStore
) => {
  if (customPackageSearchStore?.searchParams?.occupancy) {
    const occupancy = store.applyConfigOnRoomContainers(
      customPackageSearchStore?.searchParams?.occupancy
    );

    const numberOfTravelers = occupancy
      .flatMap(occ => occ.adults + occ.children)
      .reduce((acc, currentValue) => acc + currentValue, 0);

    if (numberOfTravelers > MAX_NUMBER_OF_TRAVELERS_ON_ONE_BOOKING && occupancy.length > 1) {
      return true;
    }
  }
  return false;
};
