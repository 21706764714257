/* This file was generated by running "node scripts/generateTranslations.js". */
/* Created: 2024-12-02 17:46:14 */

const Keys = {
  noSearchChangeMessage: 'noSearchChangeMessage',
  backToList: 'backToList',
  bookingErrorModalBody: 'bookingErrorModalBody',
  bookingErrorModalButton: 'bookingErrorModalButton',
  onContinueLoadingText: 'onContinueLoadingText',
  adjustNumberTraveler: 'adjustNumberTraveler',
  searchYellowSunFlightOnly: 'searchYellowSunFlightOnly',
  maxOccupancyModalTitle: 'maxOccupancyModalTitle',
  maxOccupancyModalDescription: 'maxOccupancyModalDescription'
}; export default Keys;